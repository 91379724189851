import React from "react"
import styled, { keyframes } from "styled-components"
import Layout from "../components/layout"

const Container = styled.div`
    @media (max-width: 600px){
        flex-direction: column;
        margin: auto;
        width: 50%;
    };
    @media (min-width: 601px) and (max-width: 768px){
        width:50%;
        margin:auto;
    }
    @media (min-width: 768px){
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        height: 50%;
        width: 100%;
    }
`

const WorkHours = styled.div`
    min-width: 50%;
    animation: ${fadeIn} 1.2s forwards ease-in;
`

const Title = styled.div`
  color: rgb(197, 158, 93);
  font-weight: 600;
  line-height: 1.1;
  text-align: center;
  @media (max-width: 600px){
        font-size: 1em;
        margin: 40px 0px 0px 0px;
    };
    @media (min-width: 601px) and (max-width: 768px){
        margin: 10vh auto;
    }
    @media (min-width: 768px){
        margin: 10vh auto;
        font-size: 2em;
    }
`

const SpanContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin:auto;
    @media (max-width: 600px){
        width:100%;
    };
    @media (min-width: 601px) and (max-width: 768px){
        width:100%;
    }
    @media (min-width: 768px){
        width: 35vw;
    }
    @media (min-width: 1200px){
        width: 15vw;
    }
`

const Span = styled.span`
    color: rgb(197,158,93);
    @media (max-width: 600px){
        font-size: 0.8em;
    };
    @media (min-width: 601px) and (max-width: 768px){
        font-size: 1em;
    }
    @media (min-width: 768px){
        font-size: 1.1em;
    }
`

const RoadSide = styled.div`
    width: 50%;
    margin: 0px auto;
    animation: ${fadeIn} 1.2s forwards ease-in;
    @media (max-width: 320px){
        width: 100%;
    }
`;

function fadeIn() {
    return keyframes`
    0% { opacity:0;};
    50% { opacity:0.5};
    100% { opacity:1;};
`;}

const PhoneContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    >div{
        text-align: center;
        >a{
            text-decoration: none;
            color: rgb(197,158,93);
        }
    }
`;

const Address = styled.div`
    min-height: 100px;
    display:flex;
    flex-direction: column;
    justify-content: center;
    margin: 15vh 0;
    /* animation: ${fadeIn} 1.2s forwards ease-in; */
    >iframe{
        min-height: 500px;
        border-radius: 10px;
        border: none;
        filter: invert(90%);
        @media (max-width: 768px){
            display:none;
        };
    }
    >a{
        display:none;
        @media (max-width: 768px){
            display: block;
            text-decoration: none;
            color: rgb(197, 158, 93);
            text-align: center;
            margin-bottom: 10vh;
        }
    }
    @media (max-width: 600px){
        margin: 2vh 0px 50px 0px;
        font-size: 0.8em;
    };
    @media (min-width: 601px) and (max-width: 768px){
        font-size: 1em;
    }
    @media (min-width: 768px){
        font-size: 1em;
    }
`;

const Contact = () => (
  <Layout>
    <Container>
    <WorkHours>
        <Title>Godziny pracy serwisu:</Title>
        <SpanContainer><Span>Poniedziałek</Span><Span>8-17</Span></SpanContainer>
        <SpanContainer><Span>Wtorek</Span><Span>8-17</Span></SpanContainer>
        <SpanContainer><Span>Środa</Span><Span>8-17</Span></SpanContainer>
        <SpanContainer><Span>Czwartek</Span><Span>8-17</Span></SpanContainer>
        <SpanContainer><Span>Piątek</Span><Span>8-17</Span></SpanContainer>
        <SpanContainer><Span>Sobota</Span><Span>nieczynne</Span></SpanContainer>
        <SpanContainer><Span>Niedziela</Span><Span>nieczynne</Span></SpanContainer>
    </WorkHours>
    <RoadSide>
        <Title>Całodobowa pomoc drogowa:</Title>
        <PhoneContainer>
        <div><a href="tel:+48535000150">535 000 150</a></div>
        <div><a href="tel:+48516753529">516 753 529</a></div>
        </PhoneContainer>
    </RoadSide>
    </Container>
    <Address>
    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d843.1632782199631!2d20.998623437505763!3d52.08205628000423!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47192e21ae44dc07%3A0x7c33e25591ab0a96!2sserwis%20samochodowy%20Pit%20Stop%20Auto!5e0!3m2!1spl!2spl!4v1611160294977!5m2!1spl!2spl" title="map" aria-hidden="false"></iframe>
    <a href="https://www.google.com/maps/search/?api=1&query=52.0818867,21.0002427&query_place_id=ChIJB9xEriEuGUcRlgqrkVXiM3w" >Kliknij aby otworzyć aplikacje mapy ze wskazówkami dojazdu.</a>
    {/* ChIJB9xEriEuGUcRlgqrkVXiM3w - place ID */}
    </Address>
  </Layout>
)

export default Contact
